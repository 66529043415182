import api from '@/utils/api'

export const repair = {
  state: {
    listData: [],
    scrollTop: 0,
    isAllowApply: false
  },
  mutations: {
    SET_LIST_DATA: (state, list) => {
      state.listData = list
    },
    SET_SCROLL_TOP: (state, scrollTop) => {
      state.scrollTop = scrollTop
    },
    UPDATE_REPAIR_DATA: (state, data) => {
      const result = state.listData.find(item => item.id === data.id)
      if (result) {
        result.statusName = data.statusName
        result.status = data.status
        result.transitions = data.transitions
        if (data.relateRepairTicket) {
          result.repairTicketId = data.repairTicketId
          const repairPlanInfo = data.relateRepairTicket?.repairPlanInfoList?.[0] || {}
          if (Object.keys(repairPlanInfo).includes('price')) {
            repairPlanInfo.repairPrice = repairPlanInfo.price
          }
          result.relateRepairTicket = {
            ...data.relateRepairTicket,
            hasRepairPlan: data.relateRepairTicket?.repairPlanInfoList?.length || 0,
            ...repairPlanInfo
          }
        }
      }
    },
    CLEAR_REPAIR_DATA: state => {
      state.listData = []
      state.scrollTop = 0
    },
    SET_ALLOW_APPLY: (state, isAllowApply) => {
      state.isAllowApply = isAllowApply
    }
  },
  actions: {
    updateRepairData({ commit }, id) {
      if (!id) return
      api.custapiInitialReviewOrderIdGet(id).then(res => {
        if (res.repairTicketId) {
          api.custapiRepairTicketIdGet(res.repairTicketId).then(data => {
            res.relateRepairTicket = data
            commit('UPDATE_REPAIR_DATA', res)
          })
        } else {
          commit('UPDATE_REPAIR_DATA', res)
        }
      })
    }
  }
}
